<template>
  <div id="header">
    <div id="menu-principal" class="flex" @click="toggleMenu()">
      <img
        src="@/assets/img/bars-solid.svg"
        style="width: 20px; filter: invert(1); margin-top: 6px"
      />
      <h3>Menu Principal</h3>
    </div>
    <div>
      <h3>Edmilson Ferreira - Escritor</h3>
    </div>
  </div>
  <div
    id="menu-expandido"
    v-show="menuExpandido"
    @mouseleave="menuExpandido = false"
  >
    <div
      v-for="(pg, n) in paginasVisiveis"
      :key="n"
      @click="goToPagina(pg.id)"
      :class="paginaAtiva == pg.id ? 'ativo' : 'inativo'"
    >
      {{ pg.titulo }}
    </div>
  </div>
  <table style="display: none">
    <tr>
      <td
        v-for="(pg, n) in paginasVisiveis"
        :key="n"
        @click="goToPagina(pg.id)"
        :class="paginaAtiva == pg.id ? 'ativo' : 'inativo'"
      >
        <span>
          {{ pg.titulo }}
        </span>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  data() {
    return {
      menuExpandido: false,
      paginaAtiva: 1,
      paginas: [
        { id: 1, nome: "autor", titulo: "O Autor", visivel: true },
        {
          id: 2,
          nome: "paradoxos",
          titulo: "Paradoxos Temporais",
          visivel: true,
        },
        {
          id: 3,
          nome: "tqspq",
          titulo: "Tudo o Que Se Possa Querer",
          visivel: true,
        },
        {
          id: 4,
          nome: "andre",
          titulo: "André na Terra dos Krings",
          visivel: true,
        },
        { id: 5, nome: "exilado", titulo: "O Exilado", visivel: true },
        { id: 6, nome: "depoisdofim", titulo: "Depois do Fim", visivel: true },
        { id: 7, nome: "infantis", titulo: "Livros Infantis", visivel: true },
        { id: 8, nome: "contos", titulo: "Contos", visivel: false },
        { id: 9, nome: "poesias", titulo: "Poesias", visivel: false },
      ],
      paginasVisiveis: [],
    };
  },
  methods: {
    goToPagina(id) {
      let npg = this.paginas.find((v) => v.id == id);
      if (!npg) {
        return;
      }
      this.paginaAtiva = id;
      this.menuExpandido = false;
      this.$emit("set-pagina", npg.nome);
    },
    toggleMenu() {
      this.menuExpandido = !this.menuExpandido;
    },
  },
  mounted() {
    this.paginasVisiveis = this.paginas.filter((v) => v.visivel);
  },
};
</script>
<style scoped>
#header {
  margin: 0rem 0rem;
  padding: 0rem 1rem;
  text-align: left;
  position: sticky;
  top: 0px;
  background-color: #3c3f5f;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ativo {
  background-color: #232331;
  color: #eef !important;
}

.inativo {
  background-color: #ddf;
}
.flex {
  display: flex;
}
#menu-principal > * {
  margin: 0.5rem 0.25rem;
  cursor: pointer;
}
#menu-expandido {
  position: fixed;
  top: 40px;
  left: 50px;
  background-color: #eef;
  color: #232331;
  border-radius: 0.25rem;
}

#menu-expandido > div,
#menu-expandido > div > div {
  padding: 0.5rem;
  min-width: 100px;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
  cursor: pointer;
}
#menu-expandido > div:last-child {
  border-bottom: none !important;
  border-radius: 0 0 0.25rem 0.25rem;
}
#menu-expandido > div:first-child {
  border-top: none !important;
  border-radius: 0.25rem 0.25rem 0 0;
}
</style>
