<template>
  <div id="footer">edmilsonferreira.com.br | v.1.0</div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {

  }
};
</script>
<style scoped>
#footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #101020;
  color: #fff;
  font-size: 12px;
  padding: 0.25rem;
  text-align: left;
  z-index: 100;
}
</style>