<template>
  <HeaderPage @set-pagina="setPagina" />
  <ConteudoPage :pagina="pagina" />
  <FooterPage />
</template>
<script>
import HeaderPage from "./components/HeaderPage.vue";
import ConteudoPage from "./components/ConteudoPage.vue";
import FooterPage from "./components/FooterPage.vue";

export default {
  name: "App",
  components: {
    HeaderPage,
    ConteudoPage,
    FooterPage,
  },
  data() {
    return {
      pagina: "autor",
    };
  },
  methods: {
    setPagina(pg) {
      this.pagina = pg;
    },
  },
};
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: lightgray;
  margin-top: 15px;
}

html {
  background-color: #030316;
  line-height: 1.3;
}

body {
  margin: 0px;
}
</style>
