<template>
  <div>
    <h1>O Autor</h1>
    <div class="sobre-autor">
      <div class="foto">
        <img
          src="@/assets/img/edmilson.webp"
          alt="Foto do Autor - Edmilson Ferreira"
        />
      </div>
      <div class="sobre">
        <span>
          Um amante da música e da fotografia, Edmilson Ferreira sempre gostou
          de escrever. Fatores diversos &mdash; família, trabalho e estudo
          &mdash; fizeram com que os planos de publicar livros ficassem de lado
          por um bom tempo. De certa forma, esse período foi bem aproveitado,
          pois permitiu o amadurecimento das ideias, o desenvolvimento da
          técnica e o surgimento de novas tecnologias, que agora permitem as
          publicações de forma independente por um custo mais acessível.
          <br />
          Hoje, o autor possui quatro livros adultos, um infantojuvenil e dois
          infantis finalizados, disponíveis na plataforma Kindle da Amazon e em
          papel, além de outros trabalhos em andamento.
          <br />
          O banco de ideias para novas obras não para de crescer, de forma que
          seus leitores podem esperar lançamentos periódicos, com assuntos
          diversos, que agradarão a todos os tipos de público. </span
        ><br /><br />
        <button type="button" @click="Contato()">
          Entrar em Contato com o Autor
        </button>
      </div>
    </div>
    <h1>O Processo Criativo</h1>
    <div class="sobre-autor">
      <div class="foto">
        <img
          src="@/assets/img/computador.webp"
          alt="Foto de um Computador com dois cubos mágicos à esquerda"
        />
      </div>
      <div class="sobre">
        <span>
          Tudo pode ser fonte de inspiração, desde um latido do cachorro do
          vizinho até o último filme que foi lançado no cinema. Uma visão, uma
          fala, uma frase ou uma ideia podem virar histórias curtas, contos ou
          livros inteiros.
          <br />
          O que mais gosto de fazer nos textos é extrapolar tudo o que vejo,
          sinto ou escuto, misturando sensações, imagens e emoções reais com
          outras criadas dentro da minha imaginação.
          <br />
          Existem várias frases chave e outras notas anotadas em meu computador
          e celular, prontas para virarem esboços que, depois de amadurecidos,
          serão transformados em novos livros.
        </span>
      </div>
    </div>
    <h1>Finalização: A Cereja do Bolo</h1>
    <div class="sobre-autor">
      <div class="foto">
        <img
          src="@/assets/img/fotovilma.webp"
          alt="Foto da Revisora Vilma de Oliveira"
        />
      </div>
      <div class="sobre">
        <span>
          Depois de prontos, os textos são entregues para revisão, leitura
          crítica e adequação técnica, trabalho feito com maestria pela
          competentíssima Vilma de Oliveira, graduada em Letras pela PUC Minas,
          com pós graduação em Psicopedagogia pela Universidade Grande Rio.
          <br />
          As correções feitas pela revisora são cruciais para o completo
          entendimento e fluidez dos textos. As sugestões e críticas realizadas
          por ela durante esse processo também são primordiais para que os
          enredos sejam compreendidos corretamente, de acordo com a intenção do
          autor, eliminando as possíveis ambiguidades que não sejam propositais
          e inerentes às histórias.
          <br />
          <b>Contatos da Revisora:</b><br />
          E-Mail: joannesander@gmail.com<br />
          Instagram:
          <a href="https://www.instagram.com/ferreiravilma/">@ferreiravilma</a
          ><br />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    Contato() {
      this.$emit("pedido", "outros");
    },
  },
};
</script>
<style scoped>
table {
  width: calc(100% - 50px);
  margin: 0px 25px;
}

td {
  text-align: left;
  vertical-align: top;
}

h1 {
  text-align: left;
  margin: 25px 25px 0px 25px;
}
a {
  color: #eef;
}
@media screen and (min-width: 701px) {
  .sobre-autor {
    display: flex;
    flex-flow: row;
  }
}

@media screen and (max-width: 700px) {
  .sobre-autor {
    display: block;
    flex-flow: row;
  }
}

.foto {
  margin: 25px;
}
.sobre {
  padding: 0 1rem;
  text-align: left;
  margin-top: 25px;
}
</style>
