<template>
  <div>
    <h1>Livro: Tudo o Que Se Possa Querer e Outras Histórias</h1>
    <table>
      <tr>
        <td class="flex">
          <div>
            <img
              src="@/assets/img/capa_tqspq.jpg"
              alt="Capa - Tudo o Que Se Possa Querer e Outras Histórias"
              class="capa-livro"
            />
          </div>
          <div>
            <img
              src="@/assets/img/contracapa_tqspq.jpg"
              alt="Contracapa - Tudo o Que Se Possa Querer e Outras Histórias"
              class="capa-livro"
            />
          </div>
          <div class="detalhes-livro">
            <span>
              Preço do livro: A consultar na Editora<br />
              Conteúdo: 5 Histórias, 181 páginas<br />
              Formato: A5 (14,8 x 21cm)<br />
              <button type="button" @click="Pedido()">
                Solicitar detalhes ao Autor</button
              ><br />
              <button type="button" @click="Kindle()">
                Ver livro na loja Kindle</button
              ><br />
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    Kindle() {
      window.open("https://www.amazon.com.br/dp/B07CK14DTP", "_livro");
    },
    Pedido() {
      this.$emit("pedido", "tqspq");
    },
  },
};
</script>
<style scoped>
table {
  width: calc(100% - 50px);
  margin: 0px 25px;
}

td {
  text-align: left;
  vertical-align: top;
}

h1 {
  text-align: left;
  margin: 25px 25px 0px 25px;
}
a {
  color: #eef;
}

.flex {
  display: flex;
  flex-flow: wrap;
}
</style>
