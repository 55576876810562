<template>
  <div>
    <h1>Livro: Paradoxos Temporais</h1>
    <table>
      <tr>
        <td class="flex">
          <div>
            <img
              src="@/assets/img/capa_paradoxos.jpg"
              alt="Capa - Paradoxos Temporais"
              class="capa-livro"
            />
          </div>
          <div>
            <img
              src="@/assets/img/contracapa_paradoxos.png"
              alt="Contracapa - Paradoxos Temporais"
              class="capa-livro"
            />
          </div>
          <div class="detalhes-livro">
            <span>
              Comprar este livro: R$ 25,00<br />
              Papel Branco, 215 páginas <br />
              Formato: A5 (14,8 x 21 cm)<br />
              Frete único: R$ 12,00 (1 unidade)<br />
              <button type="button" @click="Pedido()">
                Solicitar detalhes ao Autor</button
              ><br />
              <button type="button" @click="Kindle()">
                Ver livro na loja Kindle</button
              ><br />
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    Kindle() {
      window.open("https://www.amazon.com.br/dp/B09M9J34CX", "_livro");
    },
    Pedido() {
      this.$emit("pedido", "paradoxos");
    },
  },
};
</script>
<style scoped>
table {
  width: calc(100% - 50px);
  margin: 0px 25px;
}

td {
  text-align: left;
  vertical-align: top;
}

h1 {
  text-align: left;
  margin: 25px 25px 0px 25px;
}
a {
  color: #eef;
}

.flex {
  display: flex;
  flex-flow: wrap;
}
</style>
