<template>
  <div>
    <h1>Livro: A Cachorrinha Emburrada</h1>
    <table>
      <tr>
        <td class="flex flex-nowrap">
          <div>
            <img
              src="@/assets/img/capa_cachorrinha.jpg"
              alt="Capa - A Cachorrinha Emburrada"
              class="capa-livro"
            />
          </div>
          <div class="detalhes-livro">
            <span>
              Formato: Kindle (29 páginas ilustradas em cores)<br />
              <b>Sinopse:</b> Enquanto os outros bichinhos brincam pela casa, a
              cachorrinha fica quieta em sua casinha, sem vontade de fazer nada.
              O que será que está deixando a cachorrinha chateada? Acompanhe
              essa história, construída em versos, e descubra qual é o motivo
              dessa linda cachorrinha estar tão emburrada.<br />
              <button type="button" @click="Kindle('c')">
                Ver livro na loja Kindle</button
              ><br />
            </span>
          </div>
        </td>
      </tr>
    </table>
    <h1>Livro: Dente de Leão</h1>
    <table>
      <tr>
        <td class="flex flex-nowrap">
          <div>
            <img
              src="@/assets/img/capa_dentedeleao.jpg"
              alt="Capa - Dente de Leão"
              class="capa-livro"
            />
          </div>
          <div class="detalhes-livro">
            <span>
              Formato: Kindle (40 páginas ilustradas em cores)<br />
              <b>Sinopse:</b> Uma sementinha de dente-de-leão resolve deixar o
              aconchego de seu lar para viajar pelos ares, com a ajuda do vento.
              Em uma narrativa que explora, de forma lúdica, o ciclo da vida,
              este livro convida as crianças a participarem da viagem da
              sementinha pelas montanhas, vendo as belezas da natureza e
              percebendo o mundo de uma forma divertida e prazerosa.
              Dente-de-Leão é uma história para se contar antes de dormir,
              durante momentos de convivência agradável com seus filhos ou, até
              mesmo, para eles próprios lerem sozinhos. Com ilustrações ricas em
              cores e textos bem simples, este livro é perfeito para a
              biblioteca pessoal da sua criança.
              <br />
              <button type="button" @click="Kindle('d')">
                Ver livro na loja Kindle</button
              ><br />
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    Kindle(livro) {
      if (livro == "c") {
        window.open("https://www.amazon.com.br/dp/B0BH1PJ19D", "_livro");
      }
      if (livro == "d") {
        window.open(
          "https://www.amazon.com.br/gp/product/B08FGRZPBV/",
          "_livro"
        );
      }
    },
  },
};
</script>
<style scoped>
table {
  width: calc(100% - 50px);
  margin: 0px 25px;
}

td {
  text-align: left;
  vertical-align: top;
}

h1 {
  text-align: left;
  margin: 25px 25px 0px 25px;
}
a {
  color: #eef;
}

.flex {
  display: flex;
  flex-flow: wrap;
}
</style>
