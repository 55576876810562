<template>
  <div id="principal">
    <div class="modal" v-if="visivel">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Contato com o Autor</h3>
        </div>
        <div class="modal-body">
          <form ref="formContato">
            <div class="nowrap">
              <label for="motivo">Motivo do Contato: </label>
              <select
                v-model="motivoContato"
                id="motivo"
                @change="AlterarTexto(motivoContato)"
              >
                <option
                  v-for="(mt, ind) in tiposContato"
                  :key="ind"
                  :value="mt.codigo"
                >
                  {{ mt.texto }}
                </option>
              </select>
            </div>
            <div class="nowrap">
              <label for="seuNome">Como gosta que lhe chamem: *:</label>
              <input type="text" v-model="nome" id="seuNome" />
            </div>
            <div class="nowrap">
              <label for="seuEmail">Seu e-mail *:</label>
              <input type="text" v-model="email" id="seuEmail" />
            </div>
            <div class="nowrap">
              <label for="telefone">Seu telefone (opcional):</label>
              <MaskInput
                v-model="telefone"
                mask="(##) #####-####"
                id="telefone"
              />
            </div>
            <div>
              <label for="texto">Digite sua mensagem *:</label><br />
              <textarea
                v-model="mensagem"
                id="texto"
                rows="5"
                placeholder="(Digite sua mensagem)"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" @click="enviar">Enviar Mensagem</button>
          <button type="button" @click="fechar">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MaskInput } from "vue-3-mask";

export default {
  components: {
    MaskInput,
  },
  props: {
    visivel: Boolean,
    tipo: String,
  },
  data() {
    return {
      email: "",
      mensagem: "",
      motivoContato: "",
      nome: "",
      telefone: "",
      tiposContato: [
        {
          codigo: "exilado",
          texto: 'Informações/Aquisição livro "O Exilado"',
          mensagem:
            "Olá,\nEu gostaria de saber como obter o livro O Exilado.\nObrigado.",
        },
        {
          codigo: "depoisdofim",
          texto: 'Informações/Aquisição livro "Depois do Fim"',
          mensagem:
            "Olá,\nEu gostaria de saber como obter o livro Depois do Fim.\nObrigado.",
        },
        {
          codigo: "tqspq",
          texto: 'Informações/Aquisição livro "Tudo o Que Se Possa Querer"',
          mensagem:
            "Olá,\nEu gostaria de saber como obter o livro Tudo o Que Se Possa Querer e Outras Histórias.\nObrigado.",
        },
        {
          codigo: "andre",
          texto: 'Informações/Aquisição livro "André na Terra dos Krings"',
          mensagem:
            "Olá,\nEu gostaria de saber como obter o livro André na Terra dos Krings.\nObrigado.",
        },
        {
          codigo: "paradoxos",
          texto: 'Informações/Aquisição livro "Paradoxos Temporais"',
          mensagem:
            "Olá,\nEu gostaria de saber como obter o livro Paradoxos Temporais.\nObrigado.",
        },
        {
          codigo: "outros",
          texto: "Outros Motivos",
          mensagem: "",
        },
      ],
    };
  },
  methods: {
    AlterarTexto(codigo) {
      let item = this.tiposContato.find((v) => v.codigo == codigo);
      if (item) {
        this.mensagem = item.mensagem;
      }
    },
    async enviar() {
      if (!this.validarEmail(this.email)) {
        alert("Este não parece ser um e-mail válido. Verifique!");
        return;
      }
      if (this.mensagem.trim().length == 0) {
        alert("Você deve digitar uma mensagem.");
        return;
      }
      let urlAPI = localStorage.apiAddress + "/processa.php";
      //
      let tp = this.tiposContato.find((v) => v.codigo == this.motivoContato);
      let assunto =
        tp && tp.codigo != "outros"
          ? tp.texto
          : "Mensagem enviada pelo site edmilsonferreira.com.br";
      let payload = {
        acao: "enviamensagem",
        nome: this.nome,
        remetente: this.email,
        assunto: assunto,
        corpo:
          this.mensagem +
          "\n\n<b>Dados:</b><br>Nome: " +
          this.nome +
          "\nE-mail: " +
          this.email +
          "\nTelefone: " +
          this.telefone,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        mode: "no-cors",
      };
      try {
        const response = await fetch(urlAPI, requestOptions);
        //let res = response.json();
        if (!response || !response.ok) {
          alert(
            "Houve um erro no processamento da mensagem. Você pode enviar um e-mail para edmilson.contos@gmail.com . Grato pela compreensão."
          );
          this.$emit("fechar");
          return;
        }
        alert("Mensagem enviada! Obrigado!");
        this.$emit("fechar");
      } catch (error) {
        console.log(error);
        alert(
          "Houve um erro no processamento da mensagem. Você pode enviar um e-mail para edmilson.contos@gmail.com . Grato pela compreensão."
        );
        this.$emit("fechar");
      }
    },
    fechar() {
      this.$emit("fechar");
    },
    validarEmail(v) {
      if (typeof v !== "string") return false;
      v = v.trim();
      if (v.indexOf(" ") > 0) return false;
      let ar = v.indexOf("@");
      let pt = v.indexOf(".", ar);
      if (ar <= 0 || pt < 0 || pt == ar + 1) return false;
      return true;
    },
  },
  mounted() {},
  watch: {
    tipo: {
      handler(v) {
        this.motivoContato = v;
        this.AlterarTexto(v);
      },
    },
  },
};
</script>
<style scoped>
textarea {
  width: 100%;
}
form > div {
  line-height: 1.5;
  margin: 0.5rem;
}
label {
  margin-right: 1rem;
}
</style>
