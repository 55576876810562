<template>
  <div>
    <h1>Livro: O Exilado</h1>
    <table>
      <tr>
        <td class="flex">
          <div>
            <img
              src="@/assets/img/capa_exilado.jpg"
              alt="Capa - O Exilado"
              class="capa-livro"
            />
          </div>
          <div>
            <img
              src="@/assets/img/contracapa_exilado.jpg"
              alt="Contracapa - O Exilado"
              class="capa-livro"
            />
          </div>
          <div class="detalhes-livro">
            <span
              >Papel Creme, 130 páginas 15,2 x 22,8 cm <br />
              Preço: (1 unidade) 25,00 <br />
              Frete único (Brasil): R$ 12,00<br />
              <button type="button" @click="Pedido()">
                Solicitar detalhes ao Autor</button
              ><br />
              <button type="button" @click="KindleExilado()">
                Ver livro na loja Kindle</button
              ><br />
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    KindleExilado() {
      window.open("https://www.amazon.com.br/dp/B07S5BLYKJ", "_livro");
    },
    Pedido() {
      this.$emit("pedido", "exilado");
    },
  },
};
</script>
<style scoped>
table {
  width: calc(100% - 50px);
  margin: 0px 25px;
}

td {
  text-align: left;
  vertical-align: top;
}

h1 {
  text-align: left;
  margin: 25px 25px 0px 25px;
}
a {
  color: #eef;
}

.flex {
  display: flex;
  flex-flow: wrap;
}
</style>
