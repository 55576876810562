<template>
  <div id="conteudo">
    <div v-if="pagina == 'autor'">
      <sobre-autor @pedido="AbrirDialogoPedido" />
    </div>
    <div v-if="pagina == 'paradoxos'">
      <paradoxos-temporais @pedido="AbrirDialogoPedido" />
    </div>
    <div v-if="pagina == 'andre'">
      <andre-krings @pedido="AbrirDialogoPedido" />
    </div>
    <div v-if="pagina == 'depoisdofim'">
      <depois-do-fim @pedido="AbrirDialogoPedido" />
    </div>
    <div v-if="pagina == 'tqspq'">
      <tudo-possa-querer @pedido="AbrirDialogoPedido" />
    </div>
    <div v-if="pagina == 'exilado'">
      <o-exilado @pedido="AbrirDialogoPedido" />
    </div>
    <div v-if="pagina == 'infantis'">
      <livros-infantis />
    </div>
    <div>
      <modal-contato
        @fechar="dialogoContato = false"
        :visivel="dialogoContato"
        :tipo="contatoTipo"
      />
    </div>
  </div>
</template>
<script>
import AndreKrings from "./paginas/AndreKrings.vue";
import DepoisDoFim from "./paginas/DepoisDoFim.vue";
import LivrosInfantis from "./paginas/LivrosInfantis.vue";
import ModalContato from "./modals/ModalContato.vue";
import ParadoxosTemporais from "./paginas/ParadoxosTemporais.vue";
import OExilado from "./paginas/OExilado.vue";
import TudoPossaQuerer from "./paginas/TudoPossaQuerer.vue";
import SobreAutor from "./paginas/SobreAutor.vue";
import jsonContos from "@/components/json/contos.json";

export default {
  components: {
    AndreKrings,
    DepoisDoFim,
    LivrosInfantis,
    ModalContato,
    OExilado,
    SobreAutor,
    ParadoxosTemporais,
    TudoPossaQuerer,
  },
  props: {
    pagina: String,
  },
  data() {
    return {
      dialogoContato: false,
      contatoTipo: "",
      json: jsonContos,
    };
  },
  computed: {},
  methods: {
    AbrirDialogoPedido(tipo) {
      this.contatoTipo = tipo;
      this.dialogoContato = true;
    },
    pegacontos() {
      console.log(this.json.contos);
    },
  },
  created() {
    //localStorage.apiAddress = "http://edmilsonferreira.localhost/api";
    localStorage.apiAddress = "http://edmilsonferreira.com.br/api";
  },
};
</script>
<style scoped>
#conteudo {
  background: inherit;
  margin-bottom: 30px;
}
</style>
